import React, { FC } from 'react';

import { Placeholder } from '@sitecore/common';
import { CenteredRendering } from '@sitecore/types/manual/Centered';
import { Grid, Stack, Divider } from '@sparky';

const Centered: FC<CenteredRendering> = ({ params }) => {
  const colSpan = params?.colSpan ?? '8';
  const hasDivider = params?.hasDivider ?? 'false';

  return (
    <Grid
      columns={{
        initial: '8',
        md: '12',
      }}
      rowGap="6"
      alignY="center">
      <Grid.Item
        gridColumn={
          colSpan === '8'
            ? { initial: '1 / -1', lg: '2 / -2', xl: '3 / -3' }
            : { initial: '1 / -1', lg: '3 / -3', xl: '4 / -4' }
        }>
        <Stack gap={{ initial: '10', md: '16' }} direction="column">
          <Placeholder name="jss-centered-main" />
          {hasDivider === 'true' && <Divider />}
        </Stack>
      </Grid.Item>
    </Grid>
  );
};

export default Centered;
